import { useState, useEffect } from "react";
import Axios from "axios";

export default function Register({ registered, setRegister }) {

  const [isError, setIsError] = useState(false);
  const [nationalityCodeList, setNationalityCodeList] = useState([]);

  const [regData, setRegData] = useState({
    email: "",
    password: "",
    full_name: "",
    username: "",
    confirmedPassword:"",
    nationalId:"",
    nationalityCode:"",
    agency: "",
    phone: "",
  });



useEffect(() => {
  Axios.get("https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/GetCountries").then(res => {
    console.log(res.data.items)
    setNationalityCodeList(res.data.items)
  })
}, [])

  function handleSubmit(e) {
    e.preventDefault();

    // let date = new Date("Fri Jan 20 2012 11:51:36 GMT +0800").toUTCString();
    let date = new Date().toString();

    if (regData.email && regData.full_name && regData.agency && regData.phone) {
      if(regData.confirmedPassword === regData.password){

      Axios.post("https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/register", {
        email: regData.email,
        password: regData.password,
        full_name: regData.full_name,
        username: regData.username,
        nationalId: regData.nationalId,
        nationalityCode: regData.nationalityCode,
        confirmedPassword:regData.confirmedPassword,
        agency: regData.agency.replace(/[^a-z\d\s]+/gi, ""),
        // agencyCode: regData.agency.value,
        phone: regData.phone,
        date: date,
      }).then((err, res) => {
        if (err) throw err;
        console.log(res);
      });
      setRegister(true);
      console.log(regData);
    } else {
      setIsError(true);
      console.log("Password not match!")
    }
  }
  }


  function error() {
    alert("Please key in the same password!");
    setIsError(false)
  }

  const displayNationalityList = () => {
    if (nationalityCodeList && nationalityCodeList.length > 0) {
      return nationalityCodeList.map((codeList) => (
        <option value={codeList.value}>{codeList.name}</option>

      )
      )
    }
  }


  return (
    <div className="form-container">
      <h2>Registration</h2>
      {registered ? (
        <h5>
          Your details has been sent to the admin for verification, once
          approved you will get a custom virtual tour link and its password to
          host a live guided tour through email. Please contact your admin for
          any enquiries.
        </h5>
      ) : (
        <form onSubmit={(e) => handleSubmit(e)} method="POST">
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Email</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              type="email"
              value={regData.email}
              onChange={(e) =>
                setRegData({ ...regData, email: e.target.value })
              }
            />
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Full Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              type="text"
              value={regData.full_name}
              onChange={(e) =>
                setRegData({ ...regData, full_name: e.target.value })
              }
            />
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Username</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              type="text"
              value={regData.username}
              onChange={(e) =>
                setRegData({ ...regData, username: e.target.value })
              }
            />
          </div>
          {/* <div className="inputContainer">
                <label className="inputTitle"><p>Agency</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text" value={regData.agency} onChange={e => setRegData({...regData, agency: e.target.value})}/>
            </div> */}
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Agency</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              required
              placeholder="Agency"
              value={regData.agency}
              onChange={(e) =>
                setRegData({ ...regData, agency: e.target.value })
              }>
              <option style={{ display: "none" }}>Select Agency</option>
              <option value="Agency01">Agency01 Sdn Bhd</option>
              <option value="Agency02">Agency02 Sdn Bhd</option>
              <option value="Agency03">Agency03 Sdn Bhd</option>
            </select>
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Phone Number</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              type="number"
              value={regData.phone}
              onChange={(e) =>
                setRegData({ ...regData, phone: e.target.value })
              }
            />
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>National ID</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              maxLength="12"
              className="input"
              type="text"
              value={regData.nationalId}
              onChange={(e) =>
                setRegData({ ...regData, nationalId: e.target.value })
              }
              />

          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Nationality</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              required
              placeholder="Nationality Code"
              value={regData.nationalityCode}
              onChange={(e) =>
                setRegData({ ...regData, nationalityCode: e.target.value })
              }>
              <option style={{ display: "none" }}>Select Nationality</option>
              {displayNationalityList()}
            </select>
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Password</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              minLength="6"
              type="password"
              value={regData.password}
              onChange={(e) =>
                setRegData({ ...regData, password: e.target.value })
              }
            />
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Confirm Password</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              minLength="6"
              type="password"
              value={regData.confirmedPassword}
              onChange={(e) =>{
                setRegData({ ...regData, confirmedPassword: e.target.value })
              }
              }
            />
          </div>

          <button className="submit">Register</button>

          {isError
          ? error()
          :null
          }
        </form>
      )}
    </div>
  );
}
